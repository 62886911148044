@import "../../variables.scss";




.container {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding:10px 50px;
    background:white;
}

.containerLeft {
}

.containerLeft img{
    max-width:240px;
}
.containerRight {
    display:flex;
    align-items:center;
}

.link {
    font-family: $fontLight;
    font-style: normal;
    font-size: 16px;
    color: #333333;
    margin-right: 20px;
    text-decoration:none;
}
.button {
    font-family: $fontBold;
    font-style: normal;
    font-size: 16px;
    color: #333333;
    border: 1px solid black;
    background: none;
    border-radius: 10px;
    padding: 10px 20px;
}
@media (max-width: 768px) {

    .containerRight {
        display:none;
    }
    .containerLeft{
        text-align:center;
        width:100%;
    }
}