@font-face {
    font-family: "Blanka-Regular";
    src: local("Blanka-Regular"),
        url(./fonts/Blanka-Regular.otf) format("openType");
}

@font-face {
    font-family: "AirbnbCerealBlack";
    src: local("AirbnbCerealBlack"),
        url(./fonts/AirbnbCerealBlack.ttf) format("truetype");
}
@font-face {
    font-family: "AirbnbCerealBold";
    src: local("AirbnbCerealBold"),
        url(./fonts/AirbnbCerealBold.ttf) format("truetype");
}
@font-face {
    font-family: "AirbnbCerealBook";
    src: local("AirbnbCerealBook"),
        url(./fonts/AirbnbCerealBook.ttf) format("truetype");
}
@font-face {
    font-family: "AirbnbCerealExtraBold";
    src: local("AirbnbCerealExtraBold"),
        url(./fonts/AirbnbCerealExtraBold.ttf) format("truetype");
}
@font-face {
    font-family: "AirbnbCerealLight";
    src: local("AirbnbCerealLight"),
        url(./fonts/AirbnbCerealLight.ttf) format("truetype");
}

$fontCodzilla: "Blanka-Regular";

$fontExtraBold: "AirbnbCerealExtraBold";
$fontBold: "AirbnbCerealBold";
$fontBlack: "AirbnbCerealBlack";
$fontBook: "AirbnbCerealBook";
$fontLight: "AirbnbCerealLight";

$primary: #72D9CF;
$secondary: #171934;
