@import "../../variables.scss";

.primary {
  color: $primary !important;
}

.mainContainer {
  background: #F2F2F2;
  background-position: top right;
  background-repeat: no-repeat;
}

.filterSelect {
  background: #FFFFFF;
  border-radius: 10px;
  font-family: $fontBold;
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.45em;
  color: #333333;
  height: 60px;
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-transform: uppercase;
  background-image: url('../../select-arrow.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 15px;

}

.filterContainer {
  margin-top: 150px;
}

@media (max-width: 768px) {
  .filterContainer {
    margin-top: 0px;
  }
    .mainContainer {
        background-image: none!important;
    }
}

.iframe {
  width: 100%;
  height: 400px;
}
