.title-card {
    font-family: "AirbnbCerealBold";
    font-size: 70px;
    line-height: 81px;
    color: black;
}

.primary {
    font-family: "AirbnbCerealBold";
    font-size: 70px;
    line-height: 81px;
    color: #72D9CF;
}

.primarysub {
    font-family: "AirbnbCerealBold";
    font-size: 50px;
    line-height: 81px;
    color: #72D9CF;
}

.bold-subtext {
    font-family: 'AirbnbCerealBold';
    font-style: normal;
    line-height: 28px;
    letter-spacing: 0.45em;
    color: #000000;
}

.light-text {
    font-family: 'AirbnbCerealLight';
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    color: #000000;
}

.my-button {
    background-color: #72D9CF;
    border: none;
    border-radius: 32px;
    padding: 32px 68px;
    font-family: 'AirbnbCerealBold';
    font-style: normal;
    font-size: 32px;
    line-height: 140%;
    color: white;
    opacity: 0.9;
    text-underline: none;
}

    .my-button:hover {
        opacity:1
    }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media (max-width: 768px) {
    .title-card {
        font-size:50px;
        line-height:60px;
    }
}