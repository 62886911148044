@import "../../variables.scss";

$carouselHeight: 500px;

.wrapper {
  background: #F2F2F2;
}

.carousel {
  max-height: $carouselHeight;
  overflow: hidden;
}

.carousel img {
  max-height: $carouselHeight;
  overflow: hidden;
  object-fit: cover;
}

.carousel span {
  background-color: black;
  padding: 30px 10px;
  border-radius: 10px;
}


.carModelContainer {
  display: flex;
}

.carModelContainer img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 10px;
}

.carModelTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.carModelBrand {
  font-family: $fontBook;
  font-style: normal;
  font-size: 48px;
  line-height: 40px;
  text-transform: uppercase;
  color: #333333;
}

.carModelDetail {
  font-family: $fontBook;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;

  color: #4F4F4F;
}

.carModelDescription {
  font-family: $fontBold;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-top: 20px;
}

.card {
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
}

.cardTitle {
  font-family: $fontBook;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 28px;
  /* or 110% */
  text-transform: uppercase;
  /* darkblue */
  color: #1D1D28;
  margin-bottom: 10px;
  padding: 0 30px;
  text-align: center;
}

.detailRow {
  font-family: $fontLight;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
  color: #000000;
  padding: 0 30px;
}

.detailBold {
  font-family: $fontBold;
  text-transform: uppercase;
  margin-left: 10px;
}

.cardDivider {
  height: 1px;
  background: #e0e0e0;
  width: 100%;
  margin: 15px 0;
}

.price {
  padding: 0 30px;
  font-family: $fontBook;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #333333;
}

.priceDescription {
  font-family: $fontBook;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  text-transform: lowercase;
  color: #333333;
}

.section2 {
  background: linear-gradient(to bottom, transparent 0%, transparent 10%, #1D1D28 10%, #1D1D28 90%, transparent 90%, transparent 100%);
}

.cardMegaTile {
  font-family: $fontBook;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 48px;

  text-align: center;
  text-transform: uppercase;

  color: #1D1D28;
}

.rightSide {
  position: relative;
  display: flex;
  flex-direction: column;
}

.form {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formSubmitMessage {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  margin-block: 40px;
}

.link {
  color: $primary;
  text-decoration: none;
}

.link:hover {
  color: $primary;
}

.formButton {
  margin: auto;
  background: $primary;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  font-family: $fontLight;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  /* or 130% */
  text-align: center;
  letter-spacing: 0.45em;
  /* darkblue */
  color: #1D1D28;
  text-transform: uppercase;
  padding: 15px 20px;
  margin-top: 40px;
}

.cardText {
  padding: 0 30px;
  //font-family: $fontBold;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  /* or 86% */
  /* Gray 1 */
  color: #333333;
  text-align: justify;
  opacity: 0.7
}

.cardOffset {
  position: absolute;
  width: 100%;
  top: -60px;
}

@media (max-width: 992px) {
  .cardOffset {
    position: relative;
    top: 0;
    margin-top: 20px;
  }
}
