@import "../../variables.scss";


.accordion {
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    margin-bottom:20px;
    overflow:hidden;
}
.accordionContentContainer{
    border:none!important;
}
.accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-bottom: none;
    font-family: $fontBook;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    text-transform: uppercase;
    padding:30px 39px 30px 39px;
}
.accordionBody {
    background:none;
    padding:0px 40px 40px 40px;
}
.accordionArrow {
    border:none;
    background:none;
}
.subtitle {
    font-family: $fontLight;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    /* or 204% */
    letter-spacing: 0.45em;
    /* Gray 3 */
    color: #828282;
    margin-top:-20px
}