@import "../../variables.scss";



.thumbs{
    width:34px;
    height:34px;
    background:$primary;
    border-radius:50%;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:center;
}
.label {
    font-family: $fontBook;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: rgba(21, 20, 57, 0.4);
    position:absolute;
    top:-29px;
}

.thumbs:focus-visible{
    outline:none;
}
.track{

}
