@import "../../variables.scss";


.container {
    width: 100%;
    background: white;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}
.imageContainer img {
    width:100%;
}
.brandContainer {
    padding: 10px 20px;
    border-bottom: 0.5px solid #82828250;
}
.brand {
    font-family: $fontBook;
    font-style: normal;
    font-size: 22px;
    line-height: 28px;
    text-transform: uppercase;
    color: #333333;
}
.model {
    font-family: $fontBold;
    font-style: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.45em;
    color:#828282;
}
.infoContainer {
    padding: 10px 20px;
    display:flex;
    justify-content:space-between;
}
.infoContainerLeft {
}
.info {
    font-family: $fontBook;
    font-style: normal;
    font-size: 14px;
    line-height: 28px;
    color: #828282;
}
.info b {
    font-family: $fontBold;
    font-style: normal;
    font-size: 14px;
    line-height: 28px;
    color: #4F4F4F;
    text-transform:capitalize;
}
.infoContainerRight {
}
.price {
    font-family: $fontBold;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: right;
    text-transform: uppercase;
    color: #1D1D28;
}
.iva {
    font-family: $fontBook;
    font-style: normal;
    font-size: 12px;
    line-height: 28px;
    text-align: right;
    color: #828282;
}


@media (max-width: 768px) {
}