@use "../../variables.scss" as variables;

.checkboxContainer {
  margin-top: 30px;
  display: flex;
  text-align: left;
  width: 100%;
  padding: 0 20px
}

.formCheckboxLabel {
  font-family: variables.$fontBold;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  /* or 130% */
  color: #000000;
}

.formCheckbox {
  flex: none;
  width: 20px !important;
  height: 30px;
  background: #FFFFFF;
  /* Gray 2 */
  border: 1px solid #4F4F4F;
  border-radius: 10px;
  margin-right: 10px;
}

.formLabel {
  font-family: variables.$fontBold;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.45em;
  color: #333333;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
}

.formSelect {
  background: #FFFFFF;
  border-radius: 10px;
  font-family: variables.$fontBold;
  font-style: normal;
  font-size: 14px;
  color: #333333;
  height: 50px;
  width: 100%;
  margin-top: 5px;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 2px solid #BDBDBD;
}

.formInput {
  background: #FFFFFF;
  border-radius: 10px;
  font-family: variables.$fontBold;
  font-style: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.45em;
  color: #333333;
  height: 50px;
  width: 100%;
  margin-top: 5px;
  padding: 15px;
  margin-bottom: 5px;
  outline: none;
  border: 2px solid #BDBDBD;
}

.formInput::placeholder {
  color: #BDBDBD;
}

.formInput:focus {
  border: solid 2px transparent;
  background-image: linear-gradient(white, white), linear-gradient(to bottom, #1D1D28, #72D9CF);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
