@import "../../variables.scss";

.section1{
    min-height:100vh;
    background-position:center right;
    background-repeat:no-repeat;
}


.filterContainer {
    background: rgba(235, 235, 235, 0.63);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725);

    border-radius: 28px;
    text-align:left;
    overflow:hidden;
    margin-top:100px;
    backdrop-filter:blur(30px);
}
.filterMiniTitle {
    font-family: $fontBold;
    font-style: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.45em;
    color: #8A898E;
    padding-left:50px;
    padding-top:20px;
    text-transform:uppercase;
}
.filterRow {
    padding: 0px 50px;
    margin-bottom:15px;
    display:flex;
    align-items:center
}
.filterLabel {
    font-family: $fontBold;
    font-style: normal;
    font-size: 32px;
    line-height: 28px;
    color: #0B1B35;
    width:150px;
    min-width:150px;
}
.filterDivider{
    width:100%;
    height:1px;
    background-color:#828282;
    opacity:0.5;
    margin-bottom:10px;
}
.filterIcon {
    width: 100px;
    object-fit: contain;
    margin: 0px 15px;
    min-width:100px;
}
.filterIcon img {
    width:100px;
    object-fit: contain;
}

.filterSliderContainer{
    width:100%;
    margin-left:50px;
    padding:0px 17px
}
.filterSelect {
    width: 100%;
    font-family: $fontBold;
    font-style: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.45em;
    color: #333333;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background:none;
    border: none;
    text-transform: uppercase;
    background-image:url('../../select-arrow.png');
    background-repeat:no-repeat;
    background-position:calc(100% - 3px) center;
    background-size:15px;
    padding:8px
}

.filterSearch {
    background: #1D1D28;
    font-family: $fontBook;
    font-style: normal;
    font-size: 26px;
    line-height: 28px;
    color: #FFFFFF;
    width: 100%;
    padding: 10px;
    border: none;
    display:block;
    text-align:center;
    text-decoration:none;
}

.filterSearch:hover{
    text-decoration:none;
    color:white;
}



.section2 {
    min-height: 100vh;
    background-color: $secondary;
    background-position: center right;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.section2 img{
    max-width:100%;
}

.section2 h2 {
    font-family: $fontBold;
    font-style: normal;
    font-size: 64px;
    line-height: 77px;
    color: #FFFFFF;
    margin-bottom: 50px;
}

.section2 p {
    font-family: $fontBook;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #E0E0E0;
    margin-bottom:50px;
}

.section3 {
    min-height: 100vh;
    background-color: white;
    background-position: center right;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.internalContainer {
    background: $secondary;
    border-radius: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:100px;
    background-position: center right;
    background-repeat: no-repeat;
}

.contactUs {
    font-family: $fontBold;
    font-style: normal;
    font-size: 46px;
    line-height: 120%;
    color: #FFFFFF;
}
.primary {
    color: $primary!important;
}

@media (max-width: 768px) {
    .section2 {
        background-image: none !important;
    }
    .section1 {

    }

    .internalContainer {
        padding:100px 15px
    }

    .filterRow {
        flex-direction: column;
        text-align: center;
        padding: 0px 20px;
    }

    .filterIcon{
        display:none;
    }
    .filterSelect{
        margin-top:20px;
    }

    .filterSliderContainer {
        width: 100%;
        margin-left: 0px;
        margin-top:30px;
    }

    .filterContainer{
        margin-top:20px;
        margin-bottom:20px;
    }


}
@media (max-width: 992px) {
    .internalContainer{
        flex-direction:column;
    }
    .contactUs{
        text-align:center;
        margin-bottom:80px;
    }
}
