@import "../../variables.scss";

.container {
    background-position:right bottom;
    background-repeat:no-repeat;
    background-size:contain;
    padding-top:100px;
    background-color:white;
    margin-top: 20px;
}

.container img{

}

.title {
    font-family: $fontBold;
    font-style: normal;
    font-size: 24px;
    line-height: 140%;
    color: #0E1035;
    margin-bottom:30px
}

.link {
    font-family: $fontBook;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    color: #0E1035;
}

.divider {
    height: 1px;
    width: 60%;
    background: #D9D9D9;
    margin: auto;
}

.poweredBy {
    font-family: $fontBook;
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    padding:30px 20px
}